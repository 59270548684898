<template>
  <v-container fluid>
      <v-row>
          <v-col>
            <v-dialog
            v-model="dialog"
            width="700"
            transition="dialog-bottom-transition"
            >
            <template v-slot:activator="{ on, attrs }">
                <v-btn class=" blue--text mb-2 accent-2" outlined v-bind="attrs" v-on="on" @click="getBranchesReference()"><v-icon>mdi-plus</v-icon> Add Employee</v-btn>
            </template>
            <v-card>
                <v-toolbar
                dark
                outlined
                :class="isUpdate?'orange':'blue'+ 'accent-2'"
                elevation="1"
                >
                <v-btn
                    icon
                    
                    @click="dialog = false"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-spacer></v-spacer>
                <v-toolbar-items>
                    <v-btn  text @click="isUpdate? update():create()" >
                    <v-icon>{{isUpdate? 'mdi-check':'mdi-plus'}}</v-icon> {{isUpdate? 'Update Employee': 'Add Employee'}} </v-btn>
                </v-toolbar-items>
                </v-toolbar>
                <v-form ref="employee">
                    <v-container>
                        <v-row>
                            <v-col cols="12" md="6" lg="6" sm="12">
                                <v-text-field @focus="$event.target.select()" :rules="rules.stringRule" dense label="Employee Name" outlined v-model="employee.employee_name"></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6" lg="6" sm="12">
                                <v-autocomplete :items="branchReference" item-text="branch_name" item-value="branch_id" :rules="rules.stringRule" dense label="Branch" outlined v-model="employee.branch_id"></v-autocomplete>
                            </v-col>
                            <v-col cols="12" md="6" lg="6" sm="12">
                                <v-text-field @focus="$event.target.select()" :rules="rules.stringRule" dense label="Mobile" outlined v-model="employee.mobile"></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6" lg="6" sm="12">
                                <v-text-field @focus="$event.target.select()" prepend-inner-icon="mdi-currency-usd" :rules="rules.numbergRule" dense label="Bounus" outlined v-model="employee.bounus" type="number"></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6" lg="6" sm="12">
                                <v-text-field @focus="$event.target.select()" prepend-inner-icon="mdi-currency-usd" :rules="rules.numberRule" dense label="Debt" outlined v-model="employee.debt" type="number"></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6" lg="6" sm="12">
                                <v-text-field @focus="$event.target.select()" prepend-inner-icon="mdi-currency-usd" :rules="rules.numberRule" dense label="Salary" outlined v-model="employee.salary" type="number"></v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </v-card>
            </v-dialog>
            <v-card>
                <v-data-table :headers="headers" :items="employees" hide-default-footer  :items-per-page="20"  :loading="loading">
                    <template v-slot:top>
                        <v-text-field @focus="$event.target.select()"
                        v-model="table.search"
                        label="گەڕان"
                        class="mx-4"
                        ></v-text-field>
                    </template>
                    <template v-slot:[`item.bounus`]="{ item }">
                        {{'$'+item.bounus.toLocaleString()}}
                    </template>
                    <template v-slot:[`item.debt`]="{ item }">
                        {{'$'+item.debt.toLocaleString()}}
                    </template>
                    <template v-slot:[`item.salary`]="{ item }">
                        {{'$'+item.salary.toLocaleString()}}
                    </template>
                    
                    <template v-slot:[`item.actions`]="{ item }">
                        <v-hover v-slot="{ hover }" open-delay="75" close-delay="75" id="icon" >
                        <v-icon large class="ml-2" v-if="hover" color="blue accent-2" id="icon" @click="employee = {...removeProperty(item, ['branch_name'])} ; dialog=true;getBranchesReference()">mdi-pencil</v-icon>
                        <v-icon large class="ml-2" v-else>mdi-pencil</v-icon>
                        </v-hover>
                        <v-hover v-slot="{ hover }" open-delay="75" close-delay="100" id="icon" >
                            <v-icon large class="ml-2" v-if="hover" color="red accent-2" id="icon" @click="remove(item.employee_id)">mdi-delete</v-icon>
                            <v-icon large class="ml-2" v-else >mdi-delete</v-icon>
                        </v-hover>
                    </template>
                </v-data-table>
                <v-pagination color="orange accent-2" :length="Math.ceil(table.numberOfEmployees / 20)"  v-model="table.page"></v-pagination>
            </v-card>
          </v-col>
      </v-row>
      <notification/>
  </v-container>
</template>

<script>
export default {
    data() {
        return {
            table: {
                page: 1,
                numberOfEmployees: 0,
                search: ''
            },
            dialog: false,
            branchReference: [],
            employee: {
                employee_id: 0,
                employee_name: '',
                branch_id: 0,
                bounus: 0,
                debt: 0,
                debt: 0,
                salary: 0
            },
            loading: false,
            employees: [],
            headers: [
                {text: 'Employee Name', value: 'employee_name', align: 'center'},
                {text: 'Branch Name', value: 'branch_name', align: 'center'},
                {text: 'Bounus', value: 'bounus', align: 'center'},
                {text: 'Debt', value: 'debt', align: 'center'},
                {text: 'Salary', value: 'salary', align: 'center'},
                {text: 'mobile', value: 'mobile', align: 'center'},
                {text: 'کردارەکان', value: 'actions', align: 'center'},
            ],
            rules: {
                stringRule: [
                    (v) => {
                        return ( v != '' || 'please fill this field')
                    }
                ],
                numberRule: [
                    (v) => {
                        return ( v >= 0 || 'negative number invalid')
                    }
                ]
            }
        }
    },
    methods: {
        async getEmployees(){
            try {
                this.loading = true
                const {data} = await this.axios.get('/employee', {params:{page:this.table.page, search: this.table.search}})
                this.employees = data.employees
                this.table.numberOfEmployees = data.count
                this.loading = false
            } catch (error) {
                this.emitter.$emit('notify', {type: 'error', text:'an error occur!'})
            }
        },
        async create(){
            if (this.$refs.employee.validate()) {
                try {
                    await this.axios.post('/employee', this.employee)
                    this.reset()
                    this.getEmployees()
                } catch (error) {
                    console.log(error);
                    if (error.response.status == 422) {
                        return this.emitter.$emit('notify', {type: 'error', text:`${error.response.data.field} is duplicate`})
                    }
                    this.emitter.$emit('notify', {type: 'error', text:'an error occur!'})
                }
            }
        },
        async getBranchesReference(){
            try {
                const {data} = await this.axios.get('/branch/forReference')
                this.branchReference = data
            } catch (error) {
                this.emitter.$emit('notify', {type: 'error', text:'an error occur!'})
            }
        },
        async update() {
            if (this.$refs.employee.validate()) {
                try {
                    await this.axios.patch('/employee/'+ this.employee.employee_id, this.employee)
                    this.getEmployees()
                    this.reset()
                    this.dialog = false
                } catch (error) {
                    if (error.response.status == 422) {
                        return this.emitter.$emit('notify', {type: 'error', text:`${error.response.data.field} is duplicate`})
                    }
                    this.emitter.$emit('notify', {type: 'error', text:'an error occur!'})
                }
            }
        },
        remove(id){
            this.$confirm('Are you sure to delete this employee', 'confirmation', 'question').then(async () => {
                try {
                    await this.axios.delete('/employee/'+id)
                    this.getEmployees()
                } catch (error) {
                    this.emitter.$emit('notify', {type: 'error', text:'an error occur!'})
                }
            })
        },
        reset(){
            this.employee = {
                employee_id: 0,
                employee_name: '',
                branch_id: 0,
                bounus: 0,
                debt: 0,
                debt: 0,
                salary: 0
            }
            this.$refs.employee.resetValidation();
        },
        removeProperty(obj = {}, fields){
            for (let i = 0; i < fields.length; i++)  delete obj[fields[i]]
            return obj
        }
    },
    created() {
        this.getEmployees()
    },
    watch: {
        dialog(val){
            if (!val) {
                this.branchReference = []
            }
        },
        'table.search': function () {
            this.getEmployees()
        },
        'table.page': function () {
            this.getEmployees()
        }
    },
    computed: {
        isUpdate(){
            return this.employee.employee_id
        }
    }
}
</script>

<style>

</style>